import Form from './Form'
import List from './List'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useState, useEffect } from 'react'
import liff from '@line/liff'
import axios from 'axios'

function App() {
  const [profile, setProfile] = useState()
  useEffect(() => {
    liff.init({
      liffId: '1657024831-bMn7NWdd', // Use own liffId
    }).then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          setProfile(profile)
          axios.post('https://findforefather.renzhou.dev/api/user', {
            accessToken: liff.getAccessToken(),
            line_user_id: profile.userId,
            line_user_name: profile.displayName
          })
        })
      } else {
        liff.login({ redirectUri: window.location.href })
      }
    })
  },[])
  
  return (
    <Router>
      <div className="App">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
          <Switch>
            <Route path="/create"><Form profile={profile} /></Route>
            <Route path="/list"><List profile={profile} /></Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
