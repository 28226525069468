import axios from 'axios'
import { useState, useEffect } from 'react'
const List = ({ profile }) => {

  const [list, setList] = useState([])

  useEffect(() => {
    if (profile) {
      axios.get(`https://findforefather.renzhou.dev/api/list/${profile.userId}`).then(res => {
        const data = []
        res.data.data.map(e => {
          const locate = JSON.parse(e.location)
          data.push({
            id: e.id,
            title: e.title,
            locate: `${locate.lat}, ${locate.lng}`,
            note: e.note,
            distance: '-'
          })
        })
        setList(data)
      })
    }
  }, [profile])
  // const list = [
  //   {
  //     id: 1,
  //     title: '祖先001',
  //     locate: '24.00121, 120.11512',
  //     note: '越過小山丘後，往十點鐘方向，即在大樹後面。',
  //     distance: 20
  //   },
  //   {
  //     id: 2,
  //     title: '祖先002',
  //     locate: '24.00121, 120.11512',
  //     note: '越過小山丘後，往十點鐘方向，即在大樹後面。',
  //     distance: 20
  //   },
  //   {
  //     id: 3,
  //     title: '祖先003',
  //     locate: '24.00121, 120.11512',
  //     note: '越過小山丘後，往十點鐘方向，即在大樹後面。',
  //     distance: 20
  //   }
  // ]
  

  return (
      <div>
        { list && list.map((item) => (
          <div className="bg-white overflow-hidden shadow rounded-lg mb-2" key={item.id}>
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                  <a href="#" className="block focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900 truncate">{item.title}</p>
                    <p className="text-sm text-gray-500 truncate">@{item.locate}</p>
                  </a>
                </div>
                <span className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                  {item.distance} km
                </span>
              </div>
              <div className="mt-1">
                  <p className="line-clamp-2 text-sm text-gray-600">{item.note}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default List