import { LocationMarkerIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react'
import axios from 'axios'
import liff from '@line/liff'

const Form = ({ profile }) => {
  const [title, setTitle] = useState('')
  const [locate, setLocate] = useState(null)
  const [locateText, setLocateText] = useState('')
  const [locatePending, setLocatePending] = useState(false)
  const [note, setNote] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post('https://findforefather.renzhou.dev/api/item', {
      title,
      location: JSON.stringify(locate),
      note,
      line_user_id: profile.userId
    }).then(() => {
      liff.ready.then(() => {
        return liff.sendMessages([
          {
            type: "text",
            text: "新增完成！",
          },
        ])
      }).then(() => {
        liff.closeWindow();
      })
    })
  }

  const handleLocate = () => {
    if (navigator.geolocation) {
      setLocatePending(true)
      navigator.geolocation.getCurrentPosition(position => {
        console.log(position)
        const locate = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        setLocate(locate)
        setLocateText(`${position.coords.latitude}, ${position.coords.longitude}`)
        setLocatePending(false)
      })
    }
  }


  useEffect(() => {
    handleLocate()
  }, [])
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <form onSubmit={handleSubmit}>
          <section>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              名稱
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={e => setTitle(e.target.value) }
                required
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </section>
          <section className='mt-6 sm:mt-5'>
            <label htmlFor="locate" className="block text-sm font-medium text-gray-700">
              座標
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  type="text"
                  name="locate"
                  id="locate"
                  value={ locatePending ? '定位中' : locateText }
                  disabled
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                >
                </input>
              </div>
              <button
                type="button"
                onClick={handleLocate}
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <LocationMarkerIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
              </button>
            </div>
          </section>
          <section className='mt-6 sm:mt-5'>
            <label htmlFor="note" className="block text-sm font-medium text-gray-700">
              備註
            </label>
            <div className="mt-1">
              <textarea
                type="text"
                name="note"
                id="note"
                value={note}
                onChange={e => setNote(e.target.value)}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </section>
          <button
            type="submit"
            className="w-full flex justify-center mt-6 sm:mt-5 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            建立
          </button>
        </form>
      </div>
    </div>
  )
}

export default Form